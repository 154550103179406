export const EMAIL_CHANGED = "EMAIL_CHANGED";
export const PHONE_CHANGED = "PHONE_CHANGED";
export const FIRSTNAME_CHANGED = "FIRSTNAME_CHANGED";
export const LASTNAME_CHANGED = "LASTNAME_CHANGED";
export const NICKNAME_CHANGED = "NICKNAME_CHANGED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";

export const CONFIRM_PASSWORD_CHANGED = "CONFIRM_PASSWORD_CHANGED";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
export const FINALREGISTER_USER_SUCCESS = "FINALREGISTER_USER_SUCCESS";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_CLIENT_SUCCESS = "REGISTER_CLIENT_SUCCESS";
export const REGISTER_CLIENT_FAIL = "REGISTER_CLIENT_FAIL";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const FORGOT_USER_SUCCESS = "FORGOT_USER_SUCCESS";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
export const CODE_CHANGED = "CODE_CHANGED";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET_USER = "RESET_USER";
export const FORGOT_USER = "FORGOT_USER";
export const LOGIN_STATUS_CHANGED = "LOGIN_STATUS_CHANGED";
export const SUBMIT_OFFER_SUCCESS = "SUBMIT_OFFER_SUCCESS";
export const SUBMIT_OFFER_FAIL = "SUBMIT_OFFER_FAIL";
export const REQUEST_ACCESS_SUCCESS = "REQUEST_ACCESS_SUCCESS";
export const REQUEST_ACCESS_FAIL = "REQUEST_ACCESS_FAIL";
export const GET_USERS_YOU_SHARED_FAIL = "GET_USERS_YOU_SHARED_FAIL";
export const GET_USERS_YOU_HAVE_ACCESS_FAIL = "GET_USERS_YOU_HAVE_ACCESS_FAIL";
export const GET_All_PATIENT_OFFERED_ACCESS_FAIL = "GET_All_PATIENT_OFFERED_ACCESS_FAIL";
export const GET_All_ON_BORADING_DATA_FAIL = "GET_All_ON_BORADING_DATA_FAIL";
export const GET_All_ON_BORADING_CCM_DATA_FAIL = "GET_All_ON_BORADING_CCM_DATA_FAIL";
export const GET_All_FAX_REPORT_FAIL = "GET_All_FAX_REPORT_FAIL";
export const GET_ALL_CHAT_MESSAGE_FAIL = "GET_ALL_CHAT_MESSAGE_FAIL";
export const GET_ASSIGNED_CAREPROVIDERS_FAIL =
  "GET_ASSIGNED_CAREPROVIDERS_FAIL";
export const GET_USERS_LIST_FAIL = "GET_USERS_LIST_FAIL";
export const GET_USERS_READING_UNDER_YOUR_ORGANIZATION_FAIL =
  "GET_USERS_READING_UNDER_YOUR_ORGANIZATION_FAIL";
export const GET_USERS_YOU_SHARED_SUCCESS = "GET_USERS_YOU_SHARED_SUCCESS";
export const GET_ALL_DEVICE_LIST = "GET_ALL_DEVICE_LIST";
export const GET_ALL_DEVICE_LIST_FOR_ON_BOARDING = "GET_ALL_DEVICE_LIST_FOR_ON_BOARDING";
export const GET_USERS_YOU_HAVE_ACCESS_SUCCESS =
  "GET_USERS_YOU_HAVE_ACCESS_SUCCESS";
export const GET_EXPORT_ALL_ONBOARING_REPORT = "GET_EXPORT_ALL_ONBOARING_REPORT";
export const GET_EXPORT_ALL_ONBOARING_CCM_REPORT = "GET_EXPORT_ALL_ONBOARING_CCM_REPORT";
export const GET_All_PATIENT_OFFERED_ACCESS_SUCCESS = "GET_All_PATIENT_OFFERED_ACCESS_SUCCESS";  
export const GET_All_PATIENT_COUNT_SUCCESS = "GET_All_PATIENT_COUNT_SUCCESS";  
export const GET_EXPORT_ALL_PATIENT_REPORT = "GET_EXPORT_ALL_PATIENT_REPORT";
export const GET_EXPORT_CCM_RECODES = "GET_EXPORT_CCM_RECODES"; 
export const GET_PRINT_CCM_RECODES = "GET_PRINT_CCM_RECODES"; 
export const GET_All_ON_BORADING_DATA_SUCCESS = "GET_All_ON_BORADING_DATA_SUCCESS";
export const GET_All_ON_BORADING_CCM_DATA_SUCCESS = "GET_All_ON_BORADING_CCM_DATA_SUCCESS";
export const GET_All_FAX_REPORT_DATA_SUCCESS = "GET_All_FAX_REPORT_DATA_SUCCESS";
export const GET_All_CHAT_MESSAGES_SUCCESS = "GET_All_CHAT_MESSAGES_SUCCESS";
export const DO_CHAT_MESSAGE = "DO_CHAT_MESSAGE";
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const GET_COUNT_ON_BORADING_DATA_SUCCESS = "GET_COUNT_ON_BORADING_DATA_SUCCESS";
export const GET_COUNT_ON_BORADING_CCM_DATA_SUCCESS = "GET_COUNT_ON_BORADING_CCM_DATA_SUCCESS";  
export const GET_REMOVE_PATIENT_PATH = "GET_REMOVE_PATIENT_PATH";  
export const GET_NOTIFICATION_LOGS_PATH = "GET_NOTIFICATION_LOGS_PATH";  
export const GET_REMOVE_CCM_PATH = "GET_REMOVE_CCM_PATH";
export const GET_REMOVE_READING_PATH = "GET_REMOVE_READING_PATH";  
export const GET_ASSIGNED_CAREPROVIDERS_SUCCESS =
  "GET_ASSIGNED_CAREPROVIDERS_SUCCESS";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_CURRENT_MONTH_REPORT_SUCCESS =
  "GET_CURRENT_MONTH_REPORT_SUCCESS";
export const GET_CURRENT_MONTH_REPORT_FAIL = "GET_CURRENT_MONTH_REPORT_FAIL";
export const GET_CAREPROVIDERS_LIST_SUCCESS = "GET_CAREPROVIDERS_LIST_SUCCESS";
export const GET_CAREPROVIDERS_LIST_FAIL = "GET_CAREPROVIDERS_LIST_FAIL";
export const GET_REMINDER_PATIENT_LIST_SUCCESS =
  "GET_REMINDER_PATIENT_LIST_SUCCESS";
export const GET_REMINDER_PATIENT_LIST_FAIL = "GET_REMINDER_PATIENT_LIST_FAIL";
export const GET_REMINDER_LIST_SUCCESS = "GET_REMINDER_LIST_SUCCESS";
export const GET_REMINDER_LIST_FAIL = "GET_REMINDER_LIST_FAIL";
export const GET_NOTIFICATION_LOGS_SUCCESS = "GET_NOTIFICATION_LOGS_SUCCESS";
export const ONGOING_NOTIFICATION_LOGS_REQUEST = "ONGOING_NOTIFICATION_LOGS_REQUEST";
export const GET_NOTIFICATION_LOGS_FAIL = "GET_NOTIFICATION_LOGS_FAIL";
export const GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS = "GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS";
export const GET_NOTIFICATION_LOGS_EXPORT_CSV_FAIL = "GET_NOTIFICATION_LOGS_EXPORT_CSV_FAIL";
export const GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS =
  "GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS";
export const GET_MY_RULES_SUCCESS = "GET_MY_RULES_SUCCESS";
export const GET_MY_RULES_FAIL = "GET_MY_RULES_FAIL";
export const GET_CAREPROVIDER_EXPECTATIONS_SUCCESS =
  "GET_CAREPROVIDER_EXPECTATIONS_SUCCESS";
export const GET_CAREPROVIDER_EXPECTATIONS_FAIL =
  "GET_CAREPROVIDER_EXPECTATIONS_FAIL";
export const GET_RULES_SET_BY_OTHERS_SUCCESS =
  "GET_RULES_SET_BY_OTHERS_SUCCESS";
export const GET_RULES_SET_BY_OTHERS_FAIL = "GET_RULES_SET_BY_OTHERS_FAIL";
export const GET_MY_VIOLATIONS = "GET_MY_VIOLATIONS";
export const GET_MY_QUESTIONNAIRES = "GET_MY_QUESTIONNAIRES";
export const GET_MY_QUESTIONNAIRES_NAME_REPETED = "GET_MY_QUESTIONNAIRES_NAME_REPETED";
export const GET_MY_QUESTIONNAIRES_CARE_PROVIDER_SUCCESS = "GET_MY_QUESTIONNAIRES_CARE_PROVIDER_SUCCESS";
export const GET_USER_VIOLATIONS = "GET_USER_VIOLATIONS";
export const GET_VIDEO_TOKEN = "GET_VIDEO_TOKEN";
export const TIMER_STARTS = "TIMER_STARTS";
export const GET_PM_RECORD = "GET_PM_RECORD";
export const GET_PM_RECORD_NEW = "GET_PM_RECORD_NEW";
export const GET_INBOX = "GET_INBOX";
export const GET_USER_READINGS = "GET_USER_READINGS";
export const GET_PATIEN_EXPECTATION_CARE_PLAN = "GET_PATIEN_EXPECTATION_CARE_PLAN";
export const GET_SHARED_ACCOUNT_LIST = "GET_SHARED_ACCOUNT_LIST";
export const GET_STAFF_LIST = "GET_STAFF_LIST";
export const GET_USER_NOTES = "GET_USER_NOTES";
export const GET_ON_BOARDING_STATUS = "GET_ON_BOARDING_STATUS";
export const DO_GET_USER_PMSESSION_TIME = "DO_GET_USER_PMSESSION_TIME";
export const GET_USER_PMSESSION_TIME = "GET_USER_PMSESSION_TIME";
export const JUST_REGISTERED = "JUST_REGISTERED";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_GLOBAL_THRESHOLD_CARE_PROVIDER = "GET_GLOBAL_THRESHOLD_CARE_PROVIDER";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE2 = "GET_PROFILE2";
export const GET_SUPPORTS = "GET_SUPPORTS";
export const GET_NO_READINGS = "GET_NO_READINGS";
export const GET_NEW_PATIENT = "GET_NEW_PATIENT";
export const GET_PROFILE_PATIENT = "GET_PROFILE_PATIENT";
export const GET_DEVICES = "GET_DEVICES";
export const GET_CCM_SUBMISSION = "GET_CCM_SUBMISSION";
export const SAVE_POPULATION = "SAVE_POPULATION";
export const REMOVE_POPULATION = "REMOVE_POPULATION";
export const SAVE_POPULATION_EXCEPTION = "SAVE_POPULATION_EXCEPTION";
export const REMOVE_POPULATION_EXCEPTION = "REMOVE_POPULATION_EXCEPTION";
export const ONGOING_REQUEST = "ONGOING_REQUEST";
export const ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION = "ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION"
export const ONGOING_REQUEST_QUESTIONNAIRE = "ONGOING_REQUEST_QUESTIONNAIRE"
export const ONGOING_REQUEST_CARE_PLAN = "ONGOING_REQUEST_CARE_PLAN"
export const ONGOING_REQUEST_DEVICE = "ONGOING_REQUEST_DEVICE";
export const ONGOING_PATIENT_LIST_REQUEST = "ONGOING_PATIENT_LIST_REQUEST";
export const ONGOING_PATIENT_LIST_REQUEST_EXPORT = "ONGOING_PATIENT_LIST_REQUEST_EXPORT"
export const ONGOING_CHAT_MESSAGE_LOAD_REQUEST = "ONGOING_CHAT_MESSAGE_LOAD_REQUEST";
export const ONGOING_SHARED_ACCOUNT_LIST_REQUEST = "ONGOING_SHARED_ACCOUNT_LIST_REQUEST";
export const ONGOING_NOTE_LIST_REQUEST = "ONGOING_NOTE_LIST_REQUEST";

export const ONGOING_PM_SESSION_REQUEST ="ONGOING_PM_SESSION_REQUEST";
export const GET_PROFILE_CLIENT = "GET_PROFILE_CLIENT";
export const GET_CAREPROVIDERS = "GET_CAREPROVIDERS";

export const GET_CAREPROVIDER_PATIENTS = "GET_CAREPROVIDER_PATIENTS";
export const GET_CAREPROVIDER_STAFF = "GET_CAREPROVIDER_STAFF";
export const GET_STAFF_CLIENT = "GET_STAFF_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CONDITIONS = "GET_CONDITIONS";
export const RESET_CCM = "RESET_CCM";
export const GET_DEXCOMDATA = "GET_DEXCOMDATA";
export const GET_BODYTRACE = "GET_BODYTRACE";
export const GET_DEXCOM_GLUCOSE_DATA = "GET_DEXCOM_GLUCOSE_DATA";
export const GET_PATIENT_CONDITIONS = "GET_PATIENT_CONDITIONS";
export const GET_PATIENT_CCM_ANSWERS = "GET_PATIENT_CCM_ANSWERS";
export const GET_AUTENTICATE_FAX_PLUS = "GET_AUTENTICATE_FAX_PLUS";
export const GET_EXPORT_READING_RECODES = 'GET_EXPORT_READING_RECODES';
export const GET_EMAIL_READING_RECODES = 'GET_EMAIL_READING_RECODES';
export const ONGOING_BOTH_READING_REQUEST = "ONGOING_BOTH_READING_REQUEST";
export const ONGOING_USER_VIOLATION_REQUEST = "ONGOING_USER_VIOLATION_REQUEST";
export const ONGOING_PATIENT_PROFILE_REQUEST = "ONGOING_PATIENT_PROFILE_REQUEST";
export const ONGOING_PDF_EXCEL_READING_REQUEST = "ONGOING_PDF_EXCEL_READING_REQUEST";
export const ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST = "ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST";
export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const SUBMIT_INBOX = "SUBMIT_INBOX";
export const SUBMIT_CCM_FORM_INFORMATION = "SUBMIT_CCM_FORM_INFORMATION";
export const READ_INBOX_SUCCESS = "READ_INBOX_SUCCESS";
export const GET_CAREPROVIDER_LIST = "GET_CAREPROVIDER_LIST";
export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_UNREAD_INBOX_COUNT = "GET_UNREAD_INBOX_COUNT";
export const ONGOING_INBOX_REQUEST = "ONGOING_INBOX_REQUEST";
export const DELETE_INBOX_SUCCESS = "DELETE_INBOX_SUCCESS";
export const READ_ALL_INBOX_SUCCESS = "READ_ALL_INBOX_SUCCESS";
export const DELETE_ALL_INBOX_SUCCESS = "DELETE_ALL_INBOX_SUCCESS";
export const ONGOING_THIRTY_DAYS_REQUEST = "ONGOING_THIRTY_DAYS_REQUEST";
export const ONGOING_SUBMISSION_REQUEST = "ONGOING_SUBMISSION_REQUEST";
export const GET_THIRTY_DAY_SUMMARY = "GET_THIRTY_DAY_SUMMARY";
export const GET_PATIENT_SUBMISSION_DETAILS = "GET_PATIENT_SUBMISSION_DETAILS";
export const GET_PATIENT_SUBMISSION_DETAILS_ERROR = "GET_PATIENT_SUBMISSION_DETAILS_ERROR";
export const GET_PATIENT_FREQUENCY = "GET_PATIENT_FREQUENCY";
export const ONGOING_TESTING_FREQUENCY_REQUEST = "ONGOING_TESTING_FREQUENCY_REQUEST";
export const SUBMIT_TESTING_FREQUENCY = "SUBMIT_TESTING_FREQUENCY";
export const ONGOING_CAREPROVIDER_STAFF_REQUEST = "ONGOING_CAREPROVIDER_STAFF_REQUEST";
export const GET_CREATE_PATIENT_PERMISSION = "GET_CREATE_PATIENT_PERMISSION";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const SAVE_PROFILE_IMAGE = "SAVE_PROFILE_IMAGE";
export const UPDATE_SIGNATURE = "UPDATE_SIGNATURE";
export const ONGOING_BOARDING_REQUEST ="ONGOING_BOARDING_REQUEST";
export const GET_DEFAULT_PROFILE_CONFIGRATION = "GET_DEFAULT_PROFILE_CONFIGRATION";
export const SUBMIT_PROFILE_CONFIGRATION = "SUBMIT_PROFILE_CONFIGRATION";
export const SUBMIT_PROFILE_CONFIGRATION_NOTIFY = "SUBMIT_PROFILE_CONFIGRATION_NOTIFY";
export const ONGOING_SAVE_PROFILE_REQUEST = "ONGOING_SAVE_PROFILE_REQUEST";
export const GET_ADDITIONAL_CAREPROVIDER_LIST = "GET_ADDITIONAL_CAREPROVIDER_LIST";
export const SAVE_MAINCAREPROVIDER = "SAVE_MAINCAREPROVIDER";
export const ONGOING_ASSIGNED_USER_REQUEST = "ONGOING_ASSIGNED_USER_REQUEST";
export const ONGOING_ASSIGNED_CAREPROVIDER_REQUEST = "ONGOING_ASSIGNED_CAREPROVIDER_REQUEST";
export const GET_ALL_STAFF_PATIENT_LIST = "GET_ALL_STAFF_PATIENT_LIST";
export const GET_ALL_STAFF_PATIENT_LIST_FAIL = "GET_ALL_STAFF_PATIENT_LIST_FAIL";
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";
export const DELETE_ALL_STAFF_PATIENTS = "DELETE_ALL_STAFF_PATIENTS";
export const REMOVE_ALL_ASSIGNED_CAREPROVIDER = "REMOVE_ALL_ASSIGNED_CAREPROVIDER";
export const GET_EXPORT_ALL_SHARED_ACCOUNT = "GET_EXPORT_ALL_SHARED_ACCOUNT";
export const SAVE_FAMILY_SHARED_ACCOUNT = "SAVE_FAMILY_SHARED_ACCOUNT";
export const GET_EXPORT_PATIENT_POPULATE_REPORT = "GET_EXPORT_PATIENT_POPULATE_REPORT";
export const GET_REMOVE_POPULATION_FILE_PATH = "GET_REMOVE_POPULATION_FILE_PATH";
export const ONGOING_POPULATION_REQUEST = 'ONGOING_POPULATION_REQUEST';
export const ONGOING_POPULATION_DATA_REQUEST = 'ONGOING_POPULATION_DATA_REQUEST';
export const GET_VERSION_REPORTS_SUCCESS = "GET_VERSION_REPORTS_SUCCESS";
export const ONGOING_VERSION_REPORT_EXPORT_REQUEST = "ONGOING_VERSION_REPORT_EXPORT_REQUEST";
export const GET_VERSION_REPORTS_EXPORT_SUCCESS = "GET_VERSION_REPORTS_EXPORT_SUCCESS";
export const GET_VERSION_REPORTS_LOGS_PATH = "GET_VERSION_REPORTS_LOGS_PATH";
export const GET_APP_VERSION_REPORTS = "GET_APP_VERSION_REPORTS";
export const SENT_APP_UPDATE_NOTIFICATION = "SENT_APP_UPDATE_NOTIFICATION";
export const UPDATED_INDIVIUAL_EXPECTATION = "UPDATED_INDIVIUAL_EXPECTATION";
export const GET_PROFILE_AND_ROLS = "GET_PROFILE_AND_ROLS";
export const ONGOING_REQUEST_ASSIGNED_USERS_LIST = "ONGOING_REQUEST_ASSIGNED_USERS_LIST";
